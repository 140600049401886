import {ApiGeoResponse, AutocompleteResponseEvent, WhitelabelEvent} from "../../common/ts/events/Events";
import {EventBus} from "../../common/ts/events/EventBus";

function generateSecondaryOptionText(parent_names: string[]) {
    let text = "";
    for (let i = parent_names.length - 1; i >= 0; i--) {
        if (text !== "") {
            text = text.concat(', ');
        }
        text = text.concat(parent_names[i])
    }
    return text;
}

EventBus.getInstance().subscribe(AutocompleteResponseEvent.TYPE, (event: WhitelabelEvent) => {
    const autocompleteResponseEvent: AutocompleteResponseEvent = event as AutocompleteResponseEvent
    const body: ApiGeoResponse[] = autocompleteResponseEvent.payload.body;
    const {
        generateTextSuggestionElement,
        showAutocompleteDataList,
    } = autocompleteResponseEvent.payload.callbacks;
    const {
        textSuggestionCssClass,
        focusCssClass,
    } = autocompleteResponseEvent.payload.cssClassNames;
    if (body.length > 0) {
        for (let i = 0; i < body.length; i++) {
            const focus = i == 0 ? ` ${focusCssClass}` : "";
            const {id, name, url_name, parent_names} = body[i];
            let secondary_option_text = "";
            let completeText = name;
            if (parent_names != null && parent_names.length > 0) {
                secondary_option_text = ` (${generateSecondaryOptionText(parent_names)})`;
                completeText += ", " + secondary_option_text;
            }
            //language=HTML
            const html: string = `
                <div class="${textSuggestionCssClass}${focus}">
                    <i></i>
                    <div class="text">
                        <span class="principal">${name}</span>
                        <span class="secondary">${secondary_option_text}</span>
                    </div>
                </div>
            `;
            generateTextSuggestionElement(
                html,
                {
                    id: id.toString(),
                    name: url_name,
                    completeText: completeText,
                }
            );
        }
        showAutocompleteDataList();
    }
});